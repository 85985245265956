import { Link } from "gatsby"
import React from "react"
import { useTheme } from "styled-components"
import { Arrow, Close, GroupButton, HomeLinkMobile } from "./styled"
import { ThemeToggle } from "./ThemeToggle"

const NavLinks = ({ toggleFunc }) => {
  const theme = useTheme()
  return (
    <>
      <HomeLinkMobile activeClassName="activeLink" to="/">
        Home
      </HomeLinkMobile>
      <Link activeClassName="activeLink" to="/about">
        Acerca
      </Link>
      <Link activeClassName="activeLink" to="/projects">
        Proyectos
      </Link>

      <GroupButton>
        <ThemeToggle />
        <Close onClick={() => toggleFunc()} aria-label="Close Menu">
          <Arrow
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill={theme.text}
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
          </Arrow>
        </Close>
      </GroupButton>
    </>
  )
}

export default NavLinks
