import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import "./animate.css"

const BgLight = () => (
  <StaticImage
    src="../../images/bg/light.webp"
    alt="Background image"
    placeholder="blurred"
    quality="85"
    layout="fullWidth"
    style={{
      position: "fixed",
      top: "0",
      left: " 0",
      width: "100%",
      height: "100%",
      backgroundSize: "cover !important",
      background: "#373741",
      zIndex: "-1",
    }}
    className="fade"
  />
)

export default BgLight
