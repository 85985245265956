import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { useTheme } from "styled-components"
import Header from "../Header"
import BgDark from "../Image/bgDark"
import BgLight from "../Image/bgLight"
import { GridLayoutDefault, GridLayoutProjects, Hero, Main } from "./styled"

const Layout = ({ children, projects, hero }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const theme = useTheme()

  return (
    <GridLayoutDefault>
      <Header siteTitle={data.site.siteMetadata.title} />
      {projects ? (
        <GridLayoutProjects>{children}</GridLayoutProjects>
      ) : hero ? (
        <Hero>
          {theme.background === "#373741" ? <BgDark /> : <BgLight />}
          {children}
        </Hero>
      ) : (
        <Main>{children}</Main>
      )}
    </GridLayoutDefault>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
