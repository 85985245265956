import styled from "styled-components"

export const GridLayoutDefault = styled.div`
  display: grid;
  place-items: center;
  grid-template-rows: 80px auto 40px;
  height: 100vh;
`
export const GridLayoutProjects = styled.main`
  margin: 0 auto;
  max-width: 960px;
  padding: 0 1.0875rem 1.45rem;
  place-self: start;

  @media (max-width: 375px) {
    padding: 0 0.5rem 1.45rem;
  }
`

export const Main = styled.main`
  margin: 0 auto;
  max-width: 960px;
  padding: 0 1.0875rem 1.45rem;
`
export const Hero = styled.main`
  margin: 0 auto;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  transition: all 0.5s ease;
`
