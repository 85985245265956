import styled from "styled-components"

export const HeaderTag = styled.header`
  background: transparent;
  margin-bottom: 1rem;
  width: 100%;
`

export const HeaderGroup = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  margin: 0 auto;
  max-width: 960px;
  padding: 1rem 1.0875rem;

  a {
    text-decoration: none;
    font-weight: bold;
    transition: all 0.3s ease;
  }
  .activeLink {
    color: ${({ theme }) => theme.action};
    @media (max-width: 768px) {
      color: ${({ theme }) => theme.activeLink};
    }
  }
  a:hover {
    transform: translateY(5px);
  }

  @media (max-width: 414px) {
    grid-template-columns: 50% 50%;
    a {
      font-size: 1.3rem;
    }
  }
`

export const Logo = styled.h1`
  font-size: 1.4rem;
  margin: 0;
`

export const ToggleMenu = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  @media (max-width: 768px) {
    display: flex;
    justify-content: flex-end;
  }
`

export const NavLinksContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    width: 90%;
    height: 70vh;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    justify-content: space-around;
    padding-top: 2vh;
    background-color: ${({ theme }) => theme.action};
    transition: bottom 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
    right: 0;
    left: 0;
    margin: 0 auto;
    bottom: ${props => (props.open ? "-100%" : "0")};
    z-index: 2;

    a {
      margin: 2rem 0;
    }
  }
`
export const Hamburger = styled.div`
  background-color: ${({ theme }) => theme.text};
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: ${({ theme }) => theme.text};
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`
