import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import NavLinks from "../NavBar/navlinks"
import {
  Hamburger,
  HeaderGroup,
  HeaderTag,
  Logo,
  NavLinksContainer,
  ToggleMenu,
} from "./styled"

const Header = ({ siteTitle }) => {
  const [navBarIsOpen, setNavBarIsOpen] = useState(false)

  const toggleFunc = () => setNavBarIsOpen(!navBarIsOpen)

  return (
    <HeaderTag>
      <HeaderGroup>
        <Link to="/">
          <Logo activeClassName="activeLogo">{siteTitle}</Logo>
        </Link>
        <ToggleMenu navBarIsOpen={navBarIsOpen} onClick={toggleFunc}>
          {navBarIsOpen ? <Hamburger open /> : <Hamburger />}
        </ToggleMenu>

        {navBarIsOpen ? (
          <NavLinksContainer>
            <NavLinks toggleFunc={toggleFunc} />
          </NavLinksContainer>
        ) : (
          <NavLinksContainer open>
            <NavLinks />
          </NavLinksContainer>
        )}
      </HeaderGroup>
    </HeaderTag>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
