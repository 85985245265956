import { Link } from "gatsby"
import styled from "styled-components"

export const Close = styled.button`
  border: none;
  background: none;
  display: none;
  font-weight: bold;
  color: ${({ theme }) => theme.text};
  @media (max-width: 768px) {
    display: block;
  }
`
export const HomeLinkMobile = styled(Link)`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`
export const GroupButton = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: inherit;
  place-items: center;
  @media (max-width: 768px) {
    margin-top: 1.5rem;
  }
`

export const Arrow = styled.svg`
  animation: jump 0.8s ease alternate infinite;
  transform-origin: 50% 50%;
  @keyframes jump {
    from {
      transform: translateY(10px);
    }
    to {
      transform: translateY(0);
    }
  }
`
